// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-course-course-js": () => import("./../../../src/course/course.js" /* webpackChunkName: "component---src-course-course-js" */),
  "component---src-course-energy-js": () => import("./../../../src/course/energy.js" /* webpackChunkName: "component---src-course-energy-js" */),
  "component---src-course-food-js": () => import("./../../../src/course/food.js" /* webpackChunkName: "component---src-course-food-js" */),
  "component---src-course-impacts-js": () => import("./../../../src/course/impacts.js" /* webpackChunkName: "component---src-course-impacts-js" */),
  "component---src-course-intro-js": () => import("./../../../src/course/intro.js" /* webpackChunkName: "component---src-course-intro-js" */),
  "component---src-course-solutions-js": () => import("./../../../src/course/solutions.js" /* webpackChunkName: "component---src-course-solutions-js" */),
  "component---src-course-sources-js": () => import("./../../../src/course/sources.js" /* webpackChunkName: "component---src-course-sources-js" */),
  "component---src-course-transportation-js": () => import("./../../../src/course/transportation.js" /* webpackChunkName: "component---src-course-transportation-js" */),
  "component---src-course-urbanization-js": () => import("./../../../src/course/urbanization.js" /* webpackChunkName: "component---src-course-urbanization-js" */),
  "component---src-course-waste-js": () => import("./../../../src/course/waste.js" /* webpackChunkName: "component---src-course-waste-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

